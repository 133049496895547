const Slider = {
  init() {
    this.swiper = new Swiper('.jsSlider', {
      loop: true,
      pagination: '.swiper-pagination',
      nextButton: '.swiper-button.-next',
      prevButton: '.swiper-button.-prev',
    });
    // $('@project').addClass('isHidden');
  }
}

const ScrollTo = {
  init() {
    $('[data-scroll-to]').on('click', function(){
      let blockName = $(this).attr('data-scroll-to');
      let $block = $(`[data-block="${blockName}"]`);
      console.log($block);
      $('html, body').animate({
        scrollTop: $block.offset().top - 24
      });
    });
  }
}

const StartAnimation = {
  $background: $('@background'),
  projectsCheck() {
    let scroll = $(window).scrollTop();
    let wHeight = $(window).height();
    $('@project').each(function(){
      let top = $(this).offset().top;
      if(scroll + wHeight / 1.4 > top) {
        $(this).addClass('isActive');
      }
    });
  },
  init() {
    const t = this;
    t.$background.removeClass('isScaled');
    setTimeout(function(){
      t.$background.removeClass('isCentered');
      setTimeout(function(){
        $('@title').addClass('isActive');
      }, 500);
      setTimeout(function(){
        $('@desc').addClass('isActive');
      }, 1000);
      setTimeout(function(){
        $('@menu').addClass('isActive');
      }, 1500);
      setTimeout(function(){
        $('@navigation').addClass('isActive');
        t.projectsCheck();
      }, 2000);
    }, 1500);
    $(window).on('scroll', function(){
      t.projectsCheck();
    });
  }
}

$(function(){
  Slider.init();
  ScrollTo.init();
  StartAnimation.init();
  setTimeout(function(){
    $(window).scrollTop(0);
  });
});